/*Custom Css Add here*/
.aside-menu .menu-nav > .menu-item.menu-item-active > .menu-link {
  background-color: #180f09 !important;
}

.aside-menu
  .menu-nav
  > .menu-item.menu-item-active
  > .menu-link
  .svg-icon.menu-icon
  svg
  g
  > path {
  fill: #aedac9 !important;
}

.aside-menu
  .menu-nav
  > .menu-item
  > .menu-link
  .menu-icon.svg-icon
  svg
  g
  [fill] {
  fill: #624c41 !important;
}

.aside-menu
  .menu-nav
  > .menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover
  .svg-icon.menu-icon
  svg
  g
  > path {
  fill: #ca9477 !important;
  color: #ca9477 !important;
}

.aside-menu
  .menu-nav
  > .menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover
  > .menu-link {
  background-color: #180f09;
}

.aside-menu {
  background-color: #3c2c28 !important;
}

.swal2-cancel {
  color: black !important;
}

@media (min-width: 992px) {
  .brand-dark .brand {
    background-color: #3c2c28;
    box-shadow: none;
  }
}

.btn-color {
  background-color: #3c2c28 !important;
  color: #eeeae7 !important;
}

.btn-color:hover {
  background-color: #312421 !important;
  color: white !important;
  font-weight: 600 !important;
}

.MuiPaginationItem-page.Mui-selected {
  background-color: #3c2c28 !important;
  color: white !important;
}

.MuiPaginationItem-page:hover {
  background-color: #624c41 !important;
  color: white !important;
}

.imageRemovoIconSpan {
  position: absolute;
  top: 2px;
  right: 11px;
  z-index: 9;
  cursor: pointer;
}
.brownbackground {
  background-color: #3c2c28ff;
  color: #fff;
}
.RichTextEditor__root___2QXK- {
  border-radius: 0.475rem;
}
.RichTextEditor__block___2Vs_D RichTextEditor__paragraph___3NTf9 {
  font-family: inherit;
}
.public-DraftStyleDefault-ltr {
  font-family: inherit;
}

.RichTextEditor__editor___1QqIU {
  font-family: inherit;
}
.RichTextEditor__editor___1QqIU {
  height: 200px;
  overflow: auto;
}
